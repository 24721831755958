'use strict'
import '../../styles/blocks/_region-slider.scss'
import '../../styles/cards/_card--office.scss'
import '../../styles/cards/_card--region.scss'
import '../../styles/cards/_card--contact.scss'

import Swiper, {EffectCreative, Navigation, Pagination} from 'swiper';
import 'swiper/scss'
import 'swiper/scss/effect-creative'
import 'swiper/scss/pagination';

import {breakpoints} from '../breakpoints'

Swiper.use([Navigation, Pagination, EffectCreative]);

const initSlider = () => {
    const $map = $('.js-country-map');
    const $highlightedCountries = $map.find('.js-highlighted-countries')

    const changeActiveCountry = ($countrySlug) => {
        let currentRegion = $countrySlug;
        $map.attr('data-active-country', currentRegion);
        // This is needed for correct layering of overlapping outlines. Bordering country outlines aren't shown in correct z index order
        // and can only be fixed by changing the order in the DOM. (Latvia and Lithuania)
        $highlightedCountries.find(`[data-country=${currentRegion}]`).remove().appendTo($highlightedCountries)
    }

    let options = {
        effect: "creative",
        watchOverflow: true,
        observer: true,
        speed: 650,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            0: {
                creativeEffect: {
                    limitProgress: 3,
                    prev: {
                        opacity: 1,
                        translate: ["-150%", 0, -100],
                    },
                    next: {
                        opacity: 0.6,
                        translate: [0, -35, -100],
                    }
                },
            },
            [breakpoints.lg]: {
                creativeEffect: {
                    limitProgress: 3,
                    prev: {
                        opacity: 1,
                        translate: ["-150%", 0, -100],
                    },
                    next: {
                        opacity: 0.6,
                        translate: [0, -40, -100],
                    }
                },
            }
        },
        on: {
            slideChangeTransitionStart: function () {
                let $slide = $(this.slides[this.activeIndex])
                changeActiveCountry($slide.data('region'));
                this.updateProgress()
                this.updateSize()
                this.updateSlides()
                this.updateSlidesClasses()
            },
            afterInit: function () {
                let $slide = $(this.slides[this.activeIndex])
                changeActiveCountry($slide.data('region'))
            }
        }
    }

    if($('.js-slider-regions .swiper').hasClass('js-loop')){
        options.loopedSlides = 3;
        options.loop = true;
    }

    const slider = new Swiper('.js-slider-regions .swiper', options);
}

$(window).on('load', function () {
    initSlider()
});
